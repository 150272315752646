import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible';
import { Button ,Icon, Tab, Tabs,Swipe, SwipeItem,Sticky,Toast,Popup ,Form,Field, CellGroup, Col, Row , RadioGroup, Radio,Cell,Checkbox,List,Dialog ,Stepper,Lazyload  } from 'vant';
import router from './router';
import './common/css/base.less'
import './assets/icon/iconfont.css'

 import VideoPlayer from 'vue-video-player'

 require('video.js/dist/video-js.css')
 require('vue-video-player/src/custom-theme.css')
 import axios from 'axios';
 axios.defaults.baseURL='https://m.ybanj.com/api/';
 //axios.defaults.baseURL='api';
//axios.defaults.baseURL='https://hm.ybanj.com/api/';
const app = createApp(App);
const loadimage = require('./assets/loading.gif')
app.use(Button).use(Icon).use(Tab).use(Tabs).use(Swipe).use(SwipeItem).use(Sticky).use(Toast).use(Popup).use(Form).use(Field).use(CellGroup).use(Col).use(Row).use(Radio).use(RadioGroup).use(Cell).use(Checkbox).use(List).use(Dialog).use(Stepper)
.use(Lazyload, {
    loading: loadimage,
    preLoad: 1.3,
    lazyComponent: true,
    attempt: 3,
    silent:true,
  });


app.use(VideoPlayer);
app.use(router);
app.mount('#app');

