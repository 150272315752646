import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      component: () => import('../pages/myHome/MyHome'),
      redirect: '/home'
    },
    {
      path: '/home',
      component: () => import('@/pages/myHome/MyHome'),
    },
    {
      path: '/mine',
      name: 'mine',
      component: () => import('../pages/mine/Mine'),
    },
    {
      path: '/store',
      component: () => import('../pages/myStore/MyStore'),
      meta: {
        notCache: false
      }
    },
    {
      path: '/goods',
      component: () => import('../pages/myStore/MyGoods'),
    },
    {
      path: '/order',
      component: () => import('../pages/myOrder/MyOrder'),
    },
    {
      path: '/orderDetail',
      component: () => import('../pages/myOrder/orderDetail'),
    },
    {
      path: '/transferDetail',
      component: () => import('../pages/myOrder/transferDetail'),
    },
    {
      path: '/record',
      component: () => import('../pages/myRecord/MyRecord'),
    },
    {
      path: '/login',
      component: () => import('../pages/login/Login'),
    },
    {
      path: '/logind',
      component: () => import('../pages/login/LoginDetail'),
    },
    {
      path: '/topic',
      component: () => import('../pages/topic/Topic'),
    },  
    {
      path: '/setting',
      component: () => import('@/pages/mySetting/my-setting')
    },
    {
      path: '/account',
      component: () => import('@/pages/mySetting/account-security')
    },
    {
      path: '/about',
      component: () => import('@/pages/mySetting/about-sh')
    } ,
    {
      path: '/myPrize',
      component: () => import('@/pages/myStore/my-prize'),
    }
    ,
    {
      path: '/swipe',
      component: () => import('@/pages/myHome/Swipe'),
    }
    ,
    {
      path: '/swipe',
      component: () => import('@/pages/myHome/Swipe'),
    }
    ,
    {
      path: '/goodsInfo',
      component: () => import('@/pages/mine/BuyGoodsInfo'),
    },
    {
      path: '/userGoodsSingle',
      component: () => import('@/pages/mine/UserGoodsDisperseSingle'),
    },
    {
      path: '/userGoodsSeries',
      component: () => import('@/pages/mine/UserGoodsDisperseSeries'),
    },
    {
      path: '/certificate',
      component: () => import('@/pages/mine/Certificate'),
    },
    {
      path: '/donation',
      component: () => import('@/pages/mine/donation'),
    },
    {
      path: '/donationInfo',
      component: () => import('@/pages/mine/donationInfo'),
    },
    {
      path: '/shareGoodsInfo',
      component: () => import('@/pages/myStore/ShareGoodsInfo'),
    },
    {
      path: '/download',
      component: () => import('../pages/mApp/Download'),
    },
    {
      path: '/compose',
      component: () => import('@/pages/myStore/my-compose'),
      name: '合成页'
    }

    
  ],
});

export default router;
