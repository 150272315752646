let cordovaReady = Promise.resolve();

export default {
	checkWxInstall() {
		return cordovaReady.then(() => {
			return new Promise((resolve, reject) => {
				console.log('Promise Execute')
				window.Wechat.isInstalled(function (installed) {
					if (installed) {
						resolve(true);
					} else {
						reject('Wechat is not installed')
					}
				}, function (reason) {
					reject('check Wechat install failed:' + reason)
				});
			})
		});
	},

	wechatPay(params) {
		if (!params) {
			return Promise.reject('Wechat Pay: params required');
		}
		let missing_fields = [];
		for (let field of ['appid', 'partnerid', 'prepayid', 'noncestr', 'timestamp', 'sign']) {
			if (!params[field]) {
				missing_fields.push(field);
			}
		}
		if (missing_fields.length) {
			return Promise.reject('Wechat Pay: params fields missing:' + missing_fields.join(','));
		}

		return cordovaReady.then(() => {
			return new Promise((resolve, reject) => {
				window.Wechat.sendPaymentRequest(params, function () {
					resolve(true)
				}, function (reason) {
					reject(new Error(reason));
				})
				
				//window.Wechat.sendPaymentRequest(params, resolve, reject);
			})
		});
	},

	openExternalUrl(url){
		return cordovaReady.then(() => {
			window.cordova.InAppBrowser.open(url, '_system');
		});
	},

}
