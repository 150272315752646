<template>
  <router-view v-slot="{ Component }">
    <keep-alive include="mine">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { Toast, Dialog } from "vant";
import axios from "./request/request";
import wx from "weixin-jsapi";
import CordovaFunction from "@/lib/CordovaFunction";


export default {
  name: "App",
  // components: {
  //   HelloWorld
  // }
  data() {
    return {
      isShow: true,
      wxData: {},
    };
  },
  provide() {
    return {
      reload: this.reload,
      toApplyHandle: this.toApplyHandle,
    };
  },

  methods: {
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    // 判断是否还有订单未处理
    isPayHandle() {
      return new Promise((resolve1) => {
        if (this.wxData.isExist == 0) {
          Dialog.confirm({
            title: "支付订单",
            message: "你有一笔未支付订单",
            confirmButtonText: "继续支付",
            cancelButtonText: "取消支付",
            beforeClose: (action) => {
              return new Promise((resolve) => {
                if (action === "confirm") {
                  resolve(true);
                  resolve1(true);
                } else {
                  // 拦截取消操作
                  axios
                    .post("app/jspai/cancel", {
                      orderNo: this.wxData.orderNo,
                    })
                    .then(() => {
                      resolve(true);
                      resolve1(false);
                    })
                    .catch(() => {
                      resolve(true);
                      resolve1(false);
                    });
                }
              });
            },
          });
        } else {
          resolve1(true);
        }
      });
    },
    // 支付
    toApplyHandle(nums = 1, id, cancleHandle, successHandle) {
      let THIS = this;
      const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
      if (userOpenid === null) {
        Toast("请去我的页面登录后购买");
        return;
      }
       if (userOpenid.mobile === null) {
        Toast("请去我的页面登录后购买");
        return;
      }     
      // if (userOpenid.haveIc === false) {
      //   Toast("请登去我的页面实名认证后购买");
      //   return;
      // }

      // if (this.headData.payFlag == "1") {
      //   //弹出活动页
      //   //this.show.value = ref(true);
      //   this.show.value = true;
      //   console.log("show.value ---" + this.show.value);
      //   return;
      // }

      var ua = navigator.userAgent.toLowerCase();
      // eslint-disable-next-line eqeqeq
	  if(window.cordova){
		  (async ()=>{
			  try {
				  await CordovaFunction.checkWxInstall();
			  }catch (e){
				  return Toast.fail("请先安装微信");
			  }
			  
			  let orderRes = await axios.post("app/jspai", {
				  productId:id,
				  openid:0,
				  userId:userOpenid.id,
				  goodsNumber:nums,
				  wxUserId:0,
				  channel:'app'
			  });
			  if(orderRes.status === 200 && orderRes.data.code === 0){
				  Toast.clear();
				  var wxData = orderRes.data.data;
				  this.wxData = wxData;
				  if (!(await this.isPayHandle())) {
					  typeof cancleHandle === "function" && cancleHandle();
					  return false;
				  }
				  
				  let {appId:appid, paySign:sign,prepay_id:prepayid, nonceStr:noncestr, timeStamp:timestamp } = orderRes.data.data;
				  try {
					  let result = await CordovaFunction.wechatPay({
						  appid,
						  sign,
						  prepayid,
						  noncestr,
						  timestamp,
						  partnerid:1627813247
					  });
					  
					  Dialog.alert({
						  title:"微信支付",
						  message:"请在我的页面查看支付结果",
					  }).then(() => {
						  // on close
					  });
					  Toast("支付成功" + JSON.stringify(result));

					  // THIS.$router.push({ path: successRouter, query });

					  //查询是否真的支付成功
					  //启动定时器
					  typeof successHandle === "function" && successHandle();
					  this.timer = setInterval(() => {
						  //查询订单是否支付成功
						  this.queryOrderStatus(orderRes.data.orderNo);
						  console.log("定时器打印中--");
					  }, 2000);
				  }
				  catch (e){
					  Toast.fail("微信支付失败:" + e.message);
				  }
			  }
			  else{
				  Toast.fail("失败:" + orderRes.data.message);
			  }
		  })()
	  }
      else if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("微信打开的");
        const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });

        axios
          .post("app/jspai", {
            productId: id,
            openid: userOpenid.openid,
            userId: userOpenid.id,
            goodsNumber: nums,
          })
          .then(async (res) => {
            if (res.status === 200 && res.data.code === 0) {
              Toast.clear();
              var wxData = res.data.data;
              this.wxData = wxData;
              if (!(await THIS.isPayHandle())) {
                typeof cancleHandle === "function" && cancleHandle();
                return false;
              }

              wx.config({
                debug: false, //这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                appId: wxData.appId,
                timestamp: wxData.timeStamp,
                nonceStr: wxData.cnonceStr,
                signature: wxData.signature,
                jsApiList: ["chooseWXPay"],
              });

              console.log("开始支付");
              wx.ready(function () {
                console.log("开始支付---");
                wx.chooseWXPay({
                  appId: wxData.appId,
                  timestamp: wxData.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                  nonceStr: wxData.nonceStr, // 支付签名随机串，不长于 32
                  package: wxData.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                  signType: "RSA", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                  paySign: wxData.paySign, // 支付签名
                  success: function (res) {
                    //跳转到支付成功页面有这个页面
                    // $this.$router.push({
                    //     path: "/success_page",
                    //     name:"success_page"
                    // })
                    Dialog.alert({
                      title: "支付已完成",
                      message: "请在我的页面查看",
                    }).then(() => {
                      // on close
                    });
                    Toast("支付成功" + res);

                    // THIS.$router.push({ path: successRouter, query });

                    //查询是否真的支付成功
                    //启动定时器
                    typeof successHandle === "function" && successHandle();
                    this.timer = setInterval(() => {
                      //查询订单是否支付成功
                      this.queryOrderStatus(wxData.orderNo);
                      console.log("定时器打印中--");
                    }, 2000);
                  },
                  cancel: function (res) {
                    //提示引用的是mint-UI里toast
                    Toast("已取消支付");
                  },
                  fail: function (res) {
                    Toast("支付失败，请重试");
                  },
                });
              });
            } else {
              Toast.fail("失败:" + res.data.message);
            }
          })
          .catch(function (error) {
            // 请求失败处理
            Toast.fail("请检查网络");
          });
      } else {
        const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });


        axios
          .post("app/jspai", {
            productId: id,
            openid: 0,
            userId: userOpenid.id,
            goodsNumber: nums,
            channel:'h5'
          })
          .then(async (res) => {
            if (res.status === 200 && res.data.code === 0) {
              Toast.clear();
              var wxData = res.data.data;
              this.wxData = wxData;
              if (!(await THIS.isPayHandle())) {
                typeof cancleHandle === "function" && cancleHandle();
                return false;
              }
              console.log(res.data.data.prepay_id)
              var local = window.location.href+"&orderNo="+res.data.data.orderNo;
               window.location.href =
       res.data.data.prepay_id+ "&redirect_url=" +
        encodeURIComponent(local);   
            } else {
              Toast.fail("失败:" + res.data.message);
            }
          })
          .catch(function (error) {
            // 请求失败处理
            Toast.fail("请检查网络");
          });
      }

      // eslint-disable-next-line eqeqeq
    },
  },
};
</script>

<style></style>
